import React, { useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';

export default function AutoComplete(props) {
    const { items, setSelectedItem } = props;

    const [state, setState] = useState({
        inputValue: '',
        filteredItems: [],
        activeItem: 0,
    });

    const displaySuggestions = state.inputValue.length && state.filteredItems.length;

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const filteredItems = items
            .filter((item) => item.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
            .slice(0, 10);
        const activeItem = 0;
        setState((prev) => ({ ...prev, inputValue, filteredItems, activeItem }));
    };

    const selectItem = (item) => {
        setSelectedItem(item);
        const inputValue = item.name;
        const filteredItems = [];
        const activeItem = 0;
        setState((prev) => ({ ...prev, inputValue, filteredItems, activeItem }));
    };

    const handleListItemClick = (e, index) => {
        e.preventDefault();
        const item = state.filteredItems[index];
        selectItem(item);
    };

    const handleKeyDown = (e) => {
        const { activeItem, filteredItems } = state;

        // Enter key.
        if (e.keyCode === 13) {
            e.preventDefault();
            if (filteredItems && activeItem < filteredItems.length) {
                const item = filteredItems[activeItem];
                selectItem(item);
            }
        }

        // Up arrow.
        else if (e.keyCode === 38) {
            e.preventDefault();
            if (activeItem >= 0) {
                setState((prev) => ({
                    ...prev,
                    activeItem: activeItem - 1,
                }));
            }
        }

        // Down arrow.
        else if (e.keyCode === 40) {
            e.preventDefault();
            if (filteredItems && activeItem < filteredItems.length - 1) {
                setState((prev) => ({
                    ...prev,
                    activeItem: activeItem + 1,
                }));
            }
        }
    };

    return (
        <Form>
            <Form.Group id="line">
                <Form.Control
                    type="text"
                    required
                    placeholder="Enter a station name"
                    value={state.inputValue}
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
                {displaySuggestions ? (
                    <ListGroup style={{ position: 'absolute', zIndex: 100 }}>
                        {state.filteredItems.map((item, index) => (
                            <ListGroup.Item
                                key={item.id}
                                active={index === state.activeItem}
                                action
                                onClick={(e) => handleListItemClick(e, index)}
                            >
                                {item.name}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                ) : null}
            </Form.Group>
        </Form>
    );
}
