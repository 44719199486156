import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import Arrivals from './Arrivals';
import { TflStaticProvider } from '../contexts/TflStaticContext';

function App() {
    return (
        <TflStaticProvider>
            <Container
                className="d-flex align-items-center justify-content-center"
                style={{ minHeight: '100vh' }}
            >
                <Arrivals />
            </Container>
        </TflStaticProvider>
    );
}

export default App;
