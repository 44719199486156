import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Card, Alert } from 'react-bootstrap';
import AutoComplete from './AutoComplete';
import ArrivalsDetails from './ArrivalsDetails';
import { useTflStatic } from '../contexts/TflStaticContext';

export default function Arrivals() {
    const { stations } = useTflStatic();

    const [station, setStation] = useState();
    const [arrivals, setArrivals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const fetchArrivals = (station) => {
        if (station) {
            const url = `https://api.tfl.gov.uk/StopPoint/${station.id}/Arrivals`;
            console.log(`Fetching arrivals data for [${station.name}] at ${url}`);

            setLoading(true);
            setError('');

            fetch(url)
                .then((response) => {
                    setLoading(false);
                    if (response.ok) {
                        console.log('fetch completed:', response.status);
                    } else {
                        console.log('fetch error:', response.status);
                        setError(`fetch error: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.length > 0) {
                        setError('');
                    } else {
                        setError('fetch returned no data');
                    }
                    data.sort((a, b) => a.timeToStation - b.timeToStation);
                    const dataWithUniqueId = data.map((data) => {
                        const uniqueId = uuidv4();
                        const expectedArrivalAsDate = new Date(data.expectedArrival);
                        return { ...data, uniqueId, expectedArrivalAsDate };
                    });
                    setArrivals(dataWithUniqueId);
                })
                .catch((error) => {
                    setError(`fetch failed: ${error}`);
                });
        }
    };

    useEffect(() => {
        fetchArrivals(station);

        const timer = setInterval(() => {
            fetchArrivals(station);
        }, 30000);
        return () => clearInterval(timer);
    }, [station]);

    return (
        <>
            <Card className="p-2">
                <Card.Body>
                    <Card.Title className="text-center mb-4">Station Arrivals</Card.Title>
                    <AutoComplete items={stations} setSelectedItem={setStation} />
                    {loading && <Alert variant="success">Fetching data...</Alert>}
                    {error && <Alert variant="danger">{error}</Alert>}
                    {station && arrivals.length > 0 && <ArrivalsDetails arrivals={arrivals} />}
                </Card.Body>
            </Card>
        </>
    );
}
