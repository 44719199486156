import React, { useReducer, useEffect, useContext } from 'react';

const TflStaticContext = React.createContext();

export function useTflStatic() {
    return useContext(TflStaticContext);
}

const Actions = {
    AddLineData: 'add-line-data',
};

function reducer(state, action) {
    switch (action.type) {
        case Actions.AddLineData: {
            const { lineData } = action.payload;

            const { stationDetails } = state;

            for (const seq of lineData.stopPointSequences) {
                for (const sp of seq.stopPoint) {
                    const id = sp.stationId;
                    const name = sp.name;

                    //const isPaddington = /Paddington/i;
                    //if (name.match(isPaddington)) {
                    //console.log(lineId, sp);
                    //}

                    // Check for mismatched names.
                    const existingEntry = stationDetails[id];
                    if (existingEntry) {
                        if (existingEntry.name !== name) {
                            console.log(
                                `Mismatched name for station id [${id}]: ${name} vs ${existingEntry.name}`
                            );
                        }
                    } else {
                        stationDetails[id] = { id, name };
                    }
                }
            }

            const stations = Object.keys(stationDetails).map((id) => stationDetails[id]);

            stations.sort((a, b) => {
                const an = a.name;
                const bn = b.name;
                return an === bn ? 0 : an < bn ? -1 : 1;
            });

            console.log(`stations has ${stations.length} entries.`);

            return { ...state, stationDetails, stations };
        }

        default:
            return state;
    }
}

function fetchTflStaticData(dispatch) {
    const lines = [
        'bakerloo',
        //'c2c',
        'central',
        'circle',
        'district',
        'dlr',
        //'emirates-air-line',
        //'great-western-railway',
        'hammersmith-city',
        //'heathrow-express',
        'jubilee',
        //'london-overground',
        'metropolitan',
        'northern',
        'piccadilly',
        //'rb1',
        //'rb1x',
        //'rb2',
        //'rb4',
        //'rb5',
        //'rb6b',
        'tfl-rail',
        //'thames-river-services',
        'tram',
        'victoria',
        'waterloo-city',
        //'woolwich-ferry',
    ];

    for (const lineId of lines) {
        const url = `https://api.tfl.gov.uk/Line/${lineId}/Route/Sequence/All`;
        console.log(`Fetching line data for [${lineId}] at ${url}`);

        fetch(url)
            .then((response) => response.json())
            .then((lineData) => {
                //console.log(lineData);
                dispatch({
                    type: Actions.AddLineData,
                    payload: { lineId, lineData },
                });
            });
    }
}

export function TflStaticProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, { stations: [], stationDetails: {} });

    useEffect(() => {
        fetchTflStaticData(dispatch);
    }, []);

    return <TflStaticContext.Provider value={state}>{children}</TflStaticContext.Provider>;
}
