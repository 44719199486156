import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

export default function ArrivalsDetails(props) {
    const { arrivals } = props;

    const [, setTick] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setTick((prev) => prev + 1);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatDest = (a) => {
        let dest = a.destinationName;
        if (!dest) return '';

        dest = dest.replace(' Underground Station', '');
        dest = dest.replace(' DLR Station', '');

        return dest;
    };

    const formatTimeToStation = (a) => {
        if (a.currentLocation === 'At Platform') {
            return 'At Platform';
        }

        const now = new Date();
        const milliSeconds = a.expectedArrivalAsDate - now;
        const seconds = Math.max(Math.floor(milliSeconds / 1000), 0);

        const mm = Math.floor(seconds / 60);
        if (mm > 0) {
            const pp = mm >= 2 ? 's' : '';
            return `${mm} Minute${pp}`;
        } else {
            const ss = seconds % 60;
            const pp = ss >= 2 ? 's' : '';
            return `${ss} Second${pp}`;
        }
    };

    return (
        <Table striped hover className="mt-4">
            <thead>
                <tr>
                    <th>Platform</th>
                    <th>Line</th>
                    <th>Destination</th>
                    <th>Arriving</th>
                </tr>
            </thead>
            <tbody>
                {arrivals.map((a) => {
                    return (
                        <tr key={`${a.uniqueId}`}>
                            <td>{a.platformName}</td>
                            <td>{a.lineName}</td>
                            <td>{formatDest(a)}</td>
                            <td>{formatTimeToStation(a)}</td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
